function sendFormData(route, data, modal) {
    fetch(route, {
        method: 'POST',
        body: data
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Ошибка ' + response.status);
        }
        return response.json();
    })

    document.getElementById(modal).close();
}

// Скрипт для модального окна "Регистрация"
document.getElementById('register-form').addEventListener('submit', function(event) {
    var password = document.getElementById('password').value;
    var repeatPassword = document.getElementById('repeat_password').value;

    // Валидация пароля
    if (password !== repeatPassword) {
        alert('Пароли не совпадают!');
        event.preventDefault(); // Останавливаем отправку формы
        return;
    }
    // Отправка данных формы на сервер
    event.preventDefault(); // Останавливаем отправку формы
    let formData = new FormData(this);

    sendFormData('/register', formData, 'registration_modal')
});

// Скрипт для модального окна "Добавить сессию"
document.getElementById('session-form').addEventListener('submit', function(event) {
    event.preventDefault(); // Останавливаем отправку формы
    let formData = new FormData(this);
    
    sendFormData('/session', formData, 'session_modal')
})
